// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'
import join from 'lodash/join'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import smallKey from '../methods/smallKey'

import Link from '../components/link'
import '../components/link/style.less'

import ContributeForm from '../components/contribute-form'
import '../components/contribute-form/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import {
  pushObjectIntoShoppingCart,
  popObjectFromShoppingCart,
  pushNotice,
  increaseCountOfObjectInShoppingCart,
  decreaseCountOfObjectInShoppingCart,
  resetShoppingCart,
  setForwardData,
} from '../state/actions'

import website from '../seo/website.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Contribute',
  slug: '/contribute',
  abstract:
    'This book is about the very early days of Auroville based on interviews made in 1997 with Aurovilians who lived here between 1968 and 1973. The book is authored by Janet Fearn and published by Prisma Books.',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Contribute', slug: '/contribute' },
  ],
}
const formRef = React.createRef()

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "covers/img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)
  }

  /** after mount */
  componentDidMount() {
    const {
      pushObjectIntoShoppingCart,
      shoppingCartState: { totalCountOfObjects },
    } = this.props
    if (totalCountOfObjects === 0) {
      pushObjectIntoShoppingCart({
        title: `Contribution for the web book – ${website.name}`,
        price: 500,
        routeSlug: website.url,
      })
      console.log('added the first')
    }
  }

  /** standard renderer */
  render() {
    const {
      pushObjectIntoShoppingCart,
      popObjectFromShoppingCart,
      pushNotice,
      shoppingCartState,
      increaseCountOfObjectInShoppingCart,
      decreaseCountOfObjectInShoppingCart,
      resetShoppingCart,
      setForwardData,
    } = this.props
    const {
      objectsList,
      objectsCounts,
      totalCountOfObjects,
      totalPriceOfObjects,
    } = shoppingCartState

    const objectsRequested = []
    forEach(objectsList, (object) => {
      objectsRequested.push(
        `${object.title} (${objectsCounts[object.routeSlug]}${
          objectsCounts[object.routeSlug] > 1 ? ' numbers' : ''
        }, at ₹${object.price}${
          objectsCounts[object.routeSlug] > 1 ? ' each' : ''
        })`
      )
    })
    const objectsRequestedString = join(objectsRequested, '; ')
    const requestId = smallKey()

    const shopConf = {}

    const formConf = {
      macroId: 'AKfycby3Jl6vRaoYs8yq0yuUOna5s3KPCzZF1KR_BDLBU9-TTO6IHX0',
      includeTitle: false,
      includePlaceholders: true,
      includeExtra: false,
      fields: {
        name: {
          placeholder: 'Name',
        },
        email: {
          placeholder: 'Email',
        },
        phone: {
          placeholder: 'Phone',
        },
        comment: {
          placeholder:
            "If you'd like you can leave us a comment, review, feedback, or input...",
          rules: [
            {
              validator: (_, value) => {
                return Promise.resolve()
              },
            },
          ],
        },
      },
      trace: {
        success:
          'Please click on next to proceed to the payment gateway. You will be redirected to the Auroville Payment gateway. If your browser or extension detects this as a pop-up, please allow the pop-up.',
      },
      allowReset: false,
      onReset: () => resetShoppingCart(),
      canSubmit: totalCountOfObjects > 0,
      alsoInclude: {
        totalObjects: `${totalCountOfObjects}`,
        totalQuotedPrice: `${totalPriceOfObjects}`,
        requested: objectsRequestedString,
        requestId,
      },
    }

    return (
      <StandardPageWrapper pageSchema={pageSchema} {...this.props}>
        <div
          className="container"
          style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <h1>This book is published on gift-economy</h1>
          <p>
            The author and the publisher of this book have chosen to offer this
            book to read online without a pay-wall or advertisement. This is not
            for free, but available on a Gift-Economy and Pay-What-Feels-Right
            basis. It is an invitation to give what feels right for the value
            you've received from the book and/or the support you want to give us
            to continue doing our work.
          </p>
          <p>
            Publishing in this manner is an experiment in abundance where we
            trust that when we give, we will also receive. We want you to enjoy
            the book, learn from the book, but also support the book. If it was
            worth your time, please consider contributing –
          </p>
          <ContributeForm
            conf={{ shopConf, formConf }}
            refs={{ formRef }}
            requestId={requestId}
            objectsRequestedString={objectsRequestedString}
            location={this.props.location}
          />
        </div>
      </StandardPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    pushObjectIntoShoppingCart(payload) {
      dispatch(pushObjectIntoShoppingCart(payload))
    },
    popObjectFromShoppingCart(payload) {
      dispatch(popObjectFromShoppingCart(payload))
    },
    pushNotice(payload) {
      dispatch(payload(payload))
    },
    increaseCountOfObjectInShoppingCart(payload) {
      dispatch(increaseCountOfObjectInShoppingCart(payload))
    },
    decreaseCountOfObjectInShoppingCart(payload) {
      dispatch(decreaseCountOfObjectInShoppingCart(payload))
    },
    resetShoppingCart(payload) {
      dispatch(resetShoppingCart(payload))
    },
    setForwardData(payload) {
      dispatch(setForwardData(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
