// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import forEach from 'lodash/forEach'
import map from 'lodash/map'
import isNull from 'lodash/isNull'
import isInteger from 'lodash/isInteger'
import sortedUniq from 'lodash/sortedUniq'
import replace from 'lodash/replace'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Select from 'antd/lib/select'
import 'antd/lib/select/style/css'

import Input from 'antd/lib/input'
import 'antd/lib/input/style/css'

import Button from 'antd/lib/button'
import 'antd/lib/button/style/css'

import { Trail, animated, config } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import inArray from '../../methods/inArray'

import website from '../../seo/website.json'

import {
  pushNotice,
  updatePriceOfObjectInShoppingCart,
} from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { Option } = Select

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** List */
class List extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      dropdownValues: [100, 500, 1000, 2000, 5000],
      otherValue: null,
      selectedValue: 1000,
    }

    this.updateOtherValue = this.updateOtherValue.bind(this)
    this.addUpdate = this.addUpdate.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.enterFunc = this.enterFunc.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    document.addEventListener('keydown', this.enterFunc, false)
  }

  /** [componentWillUnmount description] */
  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterFunc, false)
  }

  /** [escFunction description] */
  enterFunc(event) {
    if (event.keyCode === 13) {
      this.addUpdate()
    }
  }

  /** updateOtherValue */
  updateOtherValue(e) {
    this.setState({ otherValue: e.target.value })
  }

  /** updateValue */
  updateValue(value) {
    const valueAsInt = parseInt(replace(value, '₹', ''), 10)
    const { pushNotice, updatePriceOfObjectInShoppingCart } = this.props

    this.setState(
      {
        selectedValue: valueAsInt,
      },
      () => {
        updatePriceOfObjectInShoppingCart({
          price: valueAsInt,
          routeSlug: website.url,
        })
        pushNotice({
          message: 'Okay',
          description: (
            <p style={{ marginBottom: 0 }}>Selected ₹{valueAsInt}</p>
          ),
        })
      }
    )
  }

  /** addUpdate */
  addUpdate() {
    const { otherValue, dropdownValues, selectedValue } = this.state
    const { pushNotice, updatePriceOfObjectInShoppingCart } = this.props
    if (isNull(otherValue) === true) {
      pushNotice({
        message: 'Error',
        description: <p style={{ marginBottom: 0 }}>Please enter a value</p>,
      })
    }
    if (isNull(otherValue) === false) {
      const otherValueAsInt = parseInt(otherValue, 10)

      if (isInteger(otherValueAsInt) === true) {
        if (inArray(dropdownValues, otherValueAsInt) === false) {
          dropdownValues.push(otherValueAsInt)
          const newDropdownValues = sortedUniq(dropdownValues)
          this.setState(
            {
              dropdownValues: newDropdownValues,
              selectedValue: otherValueAsInt,
            },
            () => {
              updatePriceOfObjectInShoppingCart({
                price: otherValueAsInt,
                routeSlug: website.url,
              })
              pushNotice({
                message: 'Okay',
                description: (
                  <p style={{ marginBottom: 0 }}>Selected ₹{otherValue}</p>
                ),
              })
            }
          )
        } else {
          this.setState(
            {
              selectedValue: otherValueAsInt,
            },
            () => {
              updatePriceOfObjectInShoppingCart({
                price: otherValueAsInt,
                routeSlug: website.url,
              })
              pushNotice({
                message: 'Okay',
                description: (
                  <p style={{ marginBottom: 0 }}>Selected ₹{otherValue}</p>
                ),
              })
            }
          )
        }
      } else {
        pushNotice({
          message: 'Error',
          description: (
            <p style={{ marginBottom: 0 }}>
              "{otherValue}" must be a valid number
            </p>
          ),
        })
      }
    }
  }

  /** standard renderer */
  render() {
    const {
      shoppingCartState: { objectsList, totalCountOfObjects },
      style = {},
      finishedCycle,
    } = this.props
    const { dropdownValues, otherValue, selectedValue } = this.state

    return (
      <Fragment>
        {totalCountOfObjects === 0 && (
          <p
            className="empty-objects-list"
            style={{ ...style, marginBottom: 0 }}
          >
            There seems to be an issue. Please write to
            "prisma@auroville.org.in".
          </p>
        )}
        {totalCountOfObjects !== 0 && (
          <div className="as-paragraph objects-list" style={style}>
            <div style={{ marginBottom: '1rem' }}>
              <Trail
                native
                items={objectsList}
                from={{ opacity: 0, x: -10 }}
                to={{ opacity: 1, x: 0 }}
                config={config.default}
                keys={(item) => item.routeSlug}
              >
                {(item, index) => ({ x, opacity }) => (
                  <animated.div
                    style={{
                      opacity,
                      transform: x.interpolate((d) => `translate3d(${d}%,0,0)`),
                      display: 'flex',
                      padding: 'calc(0.38 * 1rem) 0px',
                      borderTop: index !== 0 && '1px solid var(--text-color)',
                    }}
                    className="item"
                  >
                    <p
                      style={{
                        flex: 60,
                        margin: 0,
                        marginRight: '0.55rem',
                        borderBottom: '2px solid var(--text-color)',
                      }}
                      className="hidden-on-mobile"
                    >
                      {item.title}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexGrow: 1,
                      }}
                    >
                      <Select
                        defaultValue="₹1000"
                        value={`₹${selectedValue}`}
                        style={{ width: 150 }}
                        getPopupContainer={() =>
                          document.getElementById('layout')
                        }
                        dropdownRender={(menu) => (
                          <Fragment>
                            {menu}
                            <Input
                              prefix="₹"
                              style={{ flex: 'auto' }}
                              placeholder={
                                isNull(otherValue) === true
                                  ? 'Other'
                                  : otherValue
                              }
                              addonAfter={
                                <Button onClick={(event) => this.addUpdate()}>
                                  +
                                </Button>
                              }
                              onChange={(e) => this.updateOtherValue(e)}
                            />
                          </Fragment>
                        )}
                        onChange={(value) => this.updateValue(value)}
                      >
                        {map(dropdownValues, (value) => (
                          <Option value={`₹${value}`}>₹{value}</Option>
                        ))}
                      </Select>
                    </div>
                  </animated.div>
                )}
              </Trail>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    updatePriceOfObjectInShoppingCart(payload) {
      dispatch(updatePriceOfObjectInShoppingCart(payload))
    },
    pushNotice(payload) {
      dispatch(pushNotice(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------- Compose & Exports
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedList = compose(withState)(List)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedList
